




















import { DashboardItem } from '@app/models';
import fb from 'firebase/app';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import DashboardGrid from '../../components/dashboard/dashboard-grid.vue';
import ResearchTargetTabs from '../../components/research/research-target-tabs.vue';
import { ResearchModule } from '../../store/modules/research-module';
import { ResultModule } from '../../store/modules/result-module';

@Component({
  components: { DashboardGrid, ResearchTargetTabs },
})
export default class DashboardOverview extends Vue {
  @Prop({ type: Boolean, default: false })
  public paid!: boolean;

  public research = getModule(ResearchModule);
  public result = getModule(ResultModule);

  public get items() {
    return {
      ...this.result.dashboardLayout,
      ...(this.research.dashboardLayout || {}),
    };
  }

  public get targetTabs() {
    return this.$targetTabs.filter(
      t =>
        this.research.currentResearch &&
        this.research.currentResearch.target.some(c => c.startsWith(t))
    );
  }

  public async resetLayout() {
    if (!this.research.current) return;

    const { id } = this.research.current;
    const path = `dashboardLayout.${this.result.target}`;
    await this.$firebase
      .doc(`research/${id}`)
      .update(path, fb.firestore.FieldValue.delete());
  }

  public async setLayout(items: DashboardItem[]) {
    if (!this.research.current) return;

    const { id } = this.research.current;
    const path = `dashboardLayout.${this.result.target}`;
    await this.$firebase.doc(`research/${id}`).update(path, items);
  }
}
